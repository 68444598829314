.presentation-content{
    border-bottom: 1px solid var(--color-white);
    margin-bottom: 2rem;
 }
 
 .presentation-contact-options{
     display:grid;
     grid-template-columns: repeat(2,1fr);
     padding: 0.75rem 1.2rem;
 }
 
 .presentation-contact-option{
     display: flex;
     gap:5%;
 }

 .presentation-contact-option-content1 {
     color: var(--color-green);
 }

 .website-title{
    color: var(--color-white);
    font-family: inherit;
    font-size: 1.5rem;
    text-align: center;
}

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/
 @media screen and (max-width: 1024px){
    .presentation-contact-options{
        display: block;
        gap:5%;
    }
 }

 

 
 