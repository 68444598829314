.container.achievements_container {
    width: auto;
    height: fit-content;
 }
 
 
 .achievement {
    text-align: center;
     /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
 }
  .achievement img {
    display: block;
    width: 640px;
    height: 480px;
    object-fit: cover;
  }

 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 
 @media screen and (max-width: 600px){
  .achievement img {
    display: block;
    width: 320px;
    height: 240px;
    object-fit: cover;
  }
}
 