.container.testimonials_container{
    width: 40%;
    height: fit-content;
    text-align: center;
}

.testimonial{
    background: var(--color-bg-variant);
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client_review{
    text-align: left;
    margin: 0.8rem auto 2rem;
    font-weight: 300;
    display: block;
    width: 90%;
}

.client_name{
    text-align: center;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-pagination-bullet-active {
    background-color: #fff !important;
}

.swiper-pagination-bullet {
    background-color: #fff !important;
}

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
    .container.testimonials_container{
        width: 60%;
    }
 }
 
 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 
 @media screen and (max-width: 600px){
    .container.testimonials_container{
        width: var(--container-width-sm);
    }

    .client_review{
        width: var(--container-width-sm);
    }
 }

 
