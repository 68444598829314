.request-estimation-overall{
    background: var(--color-green-dark);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.container.request-estimation-container > h2
{
    color: var(--color-white);
    text-align: left;
}

.request-estimation_btn 
{
    gap:5%;
}

/* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
    .request-estimation-overall{
        display: block;
		width: 100%;
        height: fit-content;
    }
    .container.request-estimation-container{
        margin:  2rem 2rem;
    }
    .request-estimation_btn a
    {
        margin-top: 5px;
        width: auto;
    }

 }
 
 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 
 @media screen and (max-width: 600px){
    .request-estimation-overall{
        display: block;
		width: 100%;
        height: fit-content;
    }
    .container.request-estimation-container{
        margin:  2rem 2rem;
    }
    .request-estimation_btn a
    {
        margin-top: 5px;
        width: auto;
    }
 }