
/* Clear floats after image containers */
.service_container::after {
    content: "";
    clear: both;
    display: table;
}

.service-content{
    float: left;
    width: 25%;
    position: relative;
}

.service-img img{
    width:100%;
    max-height: 200px;
    opacity: 0.6;

}

.service-number {
    position: absolute;
    top: 8px;
    left: 16px;
}

.service-name {
    position: absolute;
    bottom: 50px;
    left: 16px;
}

@media screen and (max-width: 1024px) {
    .service-content {
      width: 100%;
    }
}