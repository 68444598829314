
.contact_contain-without-title{
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 12%;
    width: 50%;
}
 
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    text-align: center;
    word-break:  break-word;
 }

 .contact_option p{
   text-align: center;
   word-break:  break-word;
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    border: 1px solid transparent;
    transition: var(--transition);
 }

 .contact_option:hover{
    border-color: var(--color-green);
 }

 .icon.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
 }

 .contact_option a{
    margin-bottom: 0.7rem;
    display: inline-block;
 }

textarea, input{
   font-family: 'Poppins', sans-serif; 
}

 /*=============  FORM =============== */

form{
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.message-confirmation{
   display: none;
}

.message-error{
   display: none;
}

input, textarea{
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.5rem ;
    background: var(--color-white);
    border: 2px solid var(--color-green);
    resize: none;
    color: var(--color-black);
}

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
     .contact_contain-without-title{
        grid-template-columns: 1fr;
        gap: 2rem;
     }
     
 }
 
