
.container.services_container img{
    width:  75%;
    height: 600px;
    object-fit: cover;
    margin: auto;
}


.container.services_container .service {
    margin-top: 2rem;
}

/* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px){
    .container.services_container img{
        height: 500px;
     }
}    
/* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px){
    .container.services_container img{
        height: 300px;
    }
}    