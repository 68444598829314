@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/* Font for the company name */
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');
:root {
   --color-green: #D6E154;
   --color-green-dark:#A4AF35;
   --color-black:#000;
   --color-white:#fff;
   --color-grey:#4E5652;
   --color-grey-light:#808080;
   --transition:all 400ms ease;

   --container-width-lg:75%;
   --container-width-md:86%;
   --container-width-sm:90%;
   --swiper-theme-color:#fff;
}

body {
   font-family: 'Poppins', sans-serif; 
   background: var(--color-black);
   color:var(--color-white);
}

.company-name {
   font-family: 'Kalam', cursive;
   color:var(--color-green);
 }

h2 {
   color:var(--color-green);
   font-size: 1.5rem;
   text-align: center;
}

h1, h2, h3, h4, h5, small,p{
   margin-bottom: 1rem;
   display: block;
}

h1 {
   font-family: 'Kalam', cursive;
   font-size: 4rem;
}

p:not(footer p), h5 {
   font-size: 1.1rem;
   font-weight: 500;
}

p {
   text-align: left;
}

.alert {
   padding: 20px;
   background-color: var(--color-green);
   color: var(var(--color-white));
   margin-bottom: 15px;
   font-family: 'Poppins', sans-serif; 
 }

.btn-type1 {
   background: var(--color-green-dark);
   color:var(--color-white);
   width: max-content;
   font-weight: 600;
   display: inline-flex;
   padding: 0.75rem 1.2rem;
   border-radius: 0.4rem;
   cursor:pointer;
   transition: var(--transition);
   border: 2px solid var(--color-white);
   margin-right: 50px;
}

.btn-type2 {
   color: var(--color-green-dark);
   background:var(--color-white);
   width: max-content;
   font-weight: 600;
   display: inline-flex;
   padding: 0.75rem 1.2rem;
   border-radius: 0.4rem;
   cursor:pointer;
   transition: var(--transition);
   border: 2px solid var(--color-white);
   margin-right: 50px;
}

* {
   list-style: none;
   text-decoration: none;
   padding: 0; 
   margin: 0; 
   border: 0;
   outline: 0;
   box-sizing: border-box;
}

.icon{
   color: var(--color-green);
   position: inherit;
   font-size: 1.5em;
}

html {
   scroll-behavior: smooth;
}

.container {
   width: var(--container-width-lg);
   margin: 5rem auto 5rem auto;
}

a {
   color: var(--color-white);
   transition: var(--transition);
}

a:hover {
   color: var(--color-green);
} 

img {
   display: block;
   width: 100%;
   object-fit: cover;
   margin-bottom: 2rem;
}
/* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

@media screen and (max-width: 1024px){
   .container{
      width: var(--container-width-md);
   }

   h1 {
      font-size: 3.5rem;
   }

}
/* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px){
   .container{
      width: var(--container-width-sd);
      margin-left: 0.5rem;
      margin-right: 0.5rem;
   }

   section{
      margin-bottom: 2rem;
   }

   h1{
      font-size: 1.7rem;
   }

   h2{
      font-size: 1rem;
   }

   h3{
      font-size: 1rem;
   }

   p:not(footer p){
      font-size: 0.8rem;
   }

   .btn-type1, .btn-type2  {
      padding: 0.25rem 0.9rem;
      font-size: 0.9rem;
   }
}
