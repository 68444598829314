.footer-lists{
    display:grid;
    grid-template-columns: 20% 30% 20% 20%;
    gap: 3rem;
}

.footer-lista{
    gap: 0rem;
}

.footer-socials{
    display:flex;
    gap:5%;
}

.permalinks li, .permalinks a{ 
    color:var(--color-grey);
}

.site-email{
    word-break:  break-word;
}

.footer-copyright {
    margin-top: 3rem;
    display:grid;
    grid-template-columns: repeat(2,1fr);
}

.footer-copyright p, .footer-copyright a{
    margin-bottom: 0;
    color:var(--color-grey);
}

.footer-copyright-contact-website p {
    text-align: right;
}

.footer-logo {
    text-align: center;
}

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
    .footer-lists, .footer-copyright{
        display: block;
		width: 100%;
        margin-bottom: 40px;
    }

    .request-estimation-overall{
        display: block;
		width: 100%;
    }

    .footer-lists ul{
		margin-bottom: 40px;
    }

    .footer-copyright-contact-website p {
        text-align: left;
    }
    
 }



 
