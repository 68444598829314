
.header {
  background: var(--color-white);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
  color: var(--color-black);
  height: 400px;
  text-align: center;
}

.header h1{
    position: relative;
    top: 40%;
    transform: translateY(-50%);
}
  
.header img{
    width: 40rem;
    margin: auto;
    margin-top: 7rem;
}
  
 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
    .header {
        height: 200px;
    }

    .header img{
        width: 20rem;
        margin-top: 1rem;
    }
 }
 
 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 
 @media screen and (max-width: 600px){
    .header {
        height: 100px;
    }

    .header img{
        width: 10rem;
        margin-top: 2rem;
    }
 }

