.home-header-image{
    width: 100%;
    height: 480px;
    background-position:50% 50%;
    background-size:cover;
    background-repeat:no-repeat;
    background-image:url('../../../assets/pot de peinture.jpeg');
}

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

 @media screen and (max-width: 1024px){
    .home-header-image{
        height: 240px;
    }
 }
 
 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 
 @media screen and (max-width: 600px){
    .home-header-image{
        height: 120px;
    }
 }

