.container.experience_container h3{
    color:var(--color-green);
 }
 
 .container.experience_container p{
     color: var(--color-black);
 }
 
 .experience-item{
     background:var(--color-white);
     padding: 0.75rem 1.2rem;
     border-radius: 0.4rem;
     border: 2px solid var(--color-white);
 }
 
 .experience-lists{
     grid-template-columns: repeat(3,1fr);
     gap: 1rem;
     display: grid;
 }

.experience-lists h3{
    text-align: left;
}
 
 .experience-titles{
     text-align: center;
 }

 /* =========MEDIA QUERIES (MEDIUM DEVICES) ==========*/

@media screen and (max-width: 1024px){
    .experience-lists{
        grid-template-columns: repeat(2,1fr);
        gap: 1rem;
        display: grid;
    }
 
 }
 /* =========MEDIA QUERIES (SMALL DEVICES) ==========*/
 @media screen and (max-width: 600px){
    .experience-lists{
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;
        display: grid;
    }
 }
 