.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.equipe_container .equipe {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 125px auto
}

.equipe_container .equipe .portrait {
    width: 25%
}

.equipe_container .equipe .portrait img {
    min-width: 100%
}

.equipe_container .equipe .text-side {
    width: 75%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.equipe_container .equipe .text-side .nom {
    color: #000;
    background-image: url("/src/assets/banner-1709772_640.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1.5;
}

.equipe_container .equipe .text-side .nom h3 {
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    padding: 15px
}

.equipe_container .equipe .text-side .desc {
    background: #e4483c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.equipe_container .equipe .text-side .desc h4 {
    color: #fff;
    font-size: 27px;
    font-weight: 400;
    margin: 0;
    padding: 15px
}

.equipe_container .equipe .text-side .desc .plus:hover h4 {
    color: #3f5364
}

.equipe_container .equipe .text-side .text-box p {
    color: #999;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    padding: 15px
}

@media screen and (max-width: 1200px) {
    .equipe_container .equipe .text-side .nom h3 {
        font-size:30px
    }

    .equipe_container .equipe .text-side .desc {
        padding-left: 15px
    }

    .equipe_container .equipe .text-side .desc h4 {
        padding: 5px;
        font-size: 18px;
    }
}

@media screen and (max-width: 767px) {
    .equipe_container .equipe {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 30px auto
    }

    .equipe_container .equipe .portrait {
        width: inherit;
        max-width: 200px
    }

    .equipe_container .equipe .text-side {
        width: 100%
    }

    .equipe_container .equipe .text-side .nom h3 {
        padding-left: 5px;
        font-size: 22px;
    }

    .equipe_container .equipe .text-side .desc {
        padding-bottom: 20px;
        padding-left: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .equipe_container .equipe .text-side .text-box p {
        padding: 20px 0;
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .equipe_container .equipe .portrait {
            width: inherit;
            max-width: 200px
        }
      }
    
